export enum routes {
  DASHBOARD = '/dashboard',
  TYPOGRAPHY = '/typography',
  TABLES = '/tables',
  NOTIFICATION = '/notification',
  UI_ELEMENTS_ICONS = '/ui/icons',
  UI_ELEMENTS_CHARTS = '/ui/charts',
  UI_ELEMENTS_MAP = '/ui/map',
  LOGIN = '/login',
  USERS = '/components/users',
  FIREARMS = '/components/firearms',
  CUSTOMERS = '/components/customers',
  TRANSACTIONS = '/components/transactions',
  AD = '/components/ad'
}
