<form class="form" [formGroup]="form" (ngSubmit)="login()">
  <mat-form-field class="form__input">
    <input matInput placeholder="Username" type="email" formControlName="email">
  </mat-form-field>

  <mat-form-field class="form__input">
    <input matInput placeholder="Password" type="password" formControlName="password">
  </mat-form-field>

  <div class="form-actions">
    <button class="form-actions__login" mat-raised-button color="primary" type="submit">Login</button>
    <!-- <button class="form-actions__forget" mat-button>Forget password</button> -->
  </div>
</form>
