<div class="auth-page">
  <div class="auth-page__content-block">
    <div class="auth-page__content-wrapper">
      
      <mat-tab-group class="auth-page__group">
        <mat-tab label="Login">
          <h4 class="auth-page__group-title">Welcome</h4>
          <div class="auth-page__border-wrapper">
            <div class="auth-page__border-line"></div>
            <!-- <p class="auth-page__border-title">or</p> -->
            <div class="auth-page__border-line"></div>
          </div>

          <app-login-form (sendLoginForm)="sendLoginForm($event)"></app-login-form>
        </mat-tab>

        <!--
        <mat-tab label="New User">
          <h4 class="auth-page__group-title">Welcome!</h4>
          <p class="auth-page__group-sub-title">Create you account</p>

          <app-sign-form (sendSignForm)="sendSignForm($event)"></app-sign-form>

          <div class="auth-page__border-wrapper">
            <div class="auth-page__border-line"></div>
            <p class="auth-page__border-title">or</p>
            <div class="auth-page__border-line"></div>
          </div>
        </mat-tab>
        -->
      </mat-tab-group>

      <!-- 
      <p class="auth-page__footer-title">© 2014-{{todayDate | year}} Admin, LLC. All rights reserved.</p>
      -->
    </div>
  </div>
  <div class="auth-page__logo">
    <div class="auth-page__logo-wrapper">
      <img class="auth-page__logo-img" src="./assets/auth/logo.svg" alt="logo">
      <h6 class="auth-page__logo-title">Mammafint Admin Panel</h6>
    </div>
  </div>
</div>
