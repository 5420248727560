import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../services';
import { routes } from '../../../../consts';
import {MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

@Component({
  selector: 'app-auth-page',
  templateUrl: './auth-page.component.html',
  styleUrls: ['./auth-page.component.scss']
})
export class AuthPageComponent {
  public todayDate: Date = new Date();
  public routers: typeof routes = routes;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(
    private service: AuthService,
    private router: Router, 
    private snackBar: MatSnackBar,
  ) { }

  public sendLoginForm(e): void {
    console.log("event" , e);
    this.service.login(e).subscribe(res => {
      console.log("res role" , res['type']);
      console.log("response from user service" , res); 

      if(res['type'] == 'admin'){
        this.router.navigate([this.routers.USERS]).then();
      }else{
        this.snackBar.open("Only Admin can access this Dashboard" ,'' , {
          duration: 2000, 
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
          panelClass: ['red-snackbar']
        });
      }
    }, err => {
      console.log("there is an err" , err);//
      // show notification here 
      this.snackBar.open("Username or password not correct" ,'' , {
        duration: 2000, 
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: ['red-snackbar']
        
      });
    });
  }
  public sendSignForm(e): void {
    console.log("singon event" , e);
    this.service.sign(e).subscribe(res => {
      this.router.navigate([this.routers.USERS]).then();
    }, err => {
      console.log("there is an err" , err);//
      // show notification here 
      this.snackBar.open("There is a problem registering the user" ,'' , {
        duration: 2000, 
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: ['red-snackbar']
      });
    })
  }
}
