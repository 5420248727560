import { Injectable } from '@angular/core';
import { Observable, of,  } from 'rxjs';
import {tap} from 'rxjs/operators';
import { User } from '../models';
import { HttpClient } from '@angular/common/http';
import { API_ENDPOINT } from 'src/environments/config';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient){}
  public login(userData): Observable<any> {
    console.log("user ===>" , userData); 
   return this.http.post(`${API_ENDPOINT}login`, userData).pipe(tap(res => {
      console.log("res" , res);
      localStorage.setItem('token', res['jwt_token']);
      localStorage.setItem('id', res['id']);
      localStorage.setItem('username', res['user_name']);
    }))
  }

  public sign(data): Observable<any> {
  
   const user = {
    "first_name" : data.first_name,
    "last_name" :  data.last_name  ,
    "email" : data.email,
    "mobile" : data.phone,
    "nickname" : data.nick_name,
    "sales_term" : "dafault",
    "reminder_push" : 1,
    "photo" : null,
    "password" : data.password
   }

   return this.http.post(`${API_ENDPOINT}register`, user).pipe(tap(res => {
      console.log("response for the singon function",res);
   }))
  }

  public signOut(): void {
    localStorage.removeItem('token');
  }

  public getUser(): Observable<User> {
    return of({
      name: 'John',
      lastName: 'Smith'
    });
  }
}




