<app-layout>
  <mat-toolbar class="page-header" role="heading">
    <h1>Dashboard</h1>
    <button mat-flat-button color="warn">Latest Reports</button>
  </mat-toolbar>

  <div class="charts-wrapper">
    <div class="chart">
      <app-visits-chart [visitsChartData]="visitsChartData$ | async"></app-visits-chart>
    </div>
    <div class="chart">
      <app-performance-chart [performanceChartData]="performanceChartData$ | async"></app-performance-chart>
    </div>
    <div class="chart">
      <app-server-chart [serverChartData]="serverChartData$ | async"></app-server-chart>
    </div>
    <div class="chart">
      <app-revenue-chart [revenueCharData]="revenueChartData$ | async"></app-revenue-chart>
    </div>
  </div>

  <app-daily-line-chart [dailyLineChartData]="dailyLineChartData$ | async"></app-daily-line-chart>

  <app-project-stat-chart [projectsStatsData]="projectsStatsData$ | async"></app-project-stat-chart>

  <app-support-requests [supportRequestData]="supportRequestData$ | async"></app-support-requests>

  <app-footer></app-footer>
</app-layout>
