<div class="not-found-page">
  <div class="not-found-page__content">
    <div class="not-found-page__title">
      <img class="not-found-page__title-img" src="./assets/hot-found/logo.svg" alt="logo">
      <h3 class="not-found-page__title-text">Material Admin</h3>
    </div>

    <mat-card class="not-found-page__card">
      <mat-card-content class="not-found-page__content">
        <h1 class="not-found-page__card-title">404</h1>

        <p class="not-found-page__card-sub-title">Oops. Looks like the page you're looking for no longer exists</p>
        <p class="not-found-page__card-sub-title-second">But we're here to bring you back to safety</p>

        <button class="not-found-page__card-button" mat-raised-button color="primary" [routerLink]="routes.DASHBOARD">Back to Home</button>

      </mat-card-content>
    </mat-card>
  </div>
</div>
