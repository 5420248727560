<mat-card class="support-requests">
  <mat-card-title class="support-requests__header">
    <h5 class="support-requests__title">Support Requests</h5>
    <app-settings-menu></app-settings-menu>
  </mat-card-title>

  <mat-card-content class="support-requests__content">
    <table mat-table [dataSource]="supportRequestData" class="support-requests__table">

      <ng-container  *ngFor="let column of displayedColumns" matColumnDef="{{column}}" class="support-requests__table-row">
        <th mat-header-cell *matHeaderCellDef class="support-requests__table-row-title">{{column}}</th>
        <td mat-cell *matCellDef="let element" class="support-requests__table-content">
          <span *ngIf="column !== 'status'">{{element[column]}}</span>
          <div *ngIf="column === 'status'" class="support-requests__content-badge" [ngClass]="element[column]">
            <span>{{element[column]}}</span>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </mat-card-content>
</mat-card>
