<form class="form" [formGroup]="form" (ngSubmit)="sign()">
  <mat-form-field class="form__input">
    <input matInput placeholder="First Name" formControlName="first_name">
  </mat-form-field>
  <mat-form-field class="form__input">
    <input matInput placeholder="User Name" formControlName="last_name">
  </mat-form-field>
  <mat-form-field class="form__input">
    <input matInput placeholder="Nick Name" type="text" formControlName="nick_name">
  </mat-form-field>
  <mat-form-field class="form__input">
    <input matInput placeholder="Email Address" type="text" formControlName="email">
  </mat-form-field>
  <mat-form-field class="form__input">
    <input matInput placeholder="Phone Number" type="number" formControlName="phone">
  </mat-form-field>
  <mat-form-field class="form__input">
    <input matInput placeholder="Password" type="password" formControlName="password">
  </mat-form-field>
  <div class="form-actions">
    <button class="form-actions__create" mat-raised-button color="primary" type="submit">Create your account</button>
  </div>
</form>
